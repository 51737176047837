import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { firestore } from '../firebase';
import { collection, addDoc, query, orderBy, limit, onSnapshot, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { MessageSquare, ThumbsUp, Send } from 'lucide-react';
import Header from '../components/Header';
import Comment from '../components/Comment';
import Footer from '../components/Footer';

const CommunityPage = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [newComments, setNewComments] = useState({});
  const { user } = useAuth();

  // 사용자 이름을 처리하는 함수
  const formatUsername = (user) => {
    if (user.displayName) return user.displayName;
    if (user.email) {
      const atIndex = user.email.indexOf('@');
      return atIndex > 0 ? user.email.slice(0, atIndex) : user.email;
    }
    return user.uid.slice(0, 8); // UID의 첫 8자만 사용
  };

  useEffect(() => {
    const q = query(collection(firestore, 'posts'), orderBy('createdAt', 'desc'), limit(20));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const posts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        comments: doc.data().comments || [],
        likes: Array.isArray(doc.data().likes) ? doc.data().likes : [],
      }));
      setPosts(posts);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPost.trim()) return;

    await addDoc(collection(firestore, 'posts'), {
      content: newPost,
      authorId: user.uid,
      authorName: formatUsername(user),
      createdAt: new Date(),
      likes: [],
      comments: []
    });

    setNewPost('');
  };

  const handleCommentSubmit = async (postId) => {
    if (!newComments[postId]?.trim()) return;

    const postRef = doc(firestore, 'posts', postId);
    await updateDoc(postRef, {
      comments: arrayUnion({
        content: newComments[postId],
        authorId: user.uid,
        authorName: formatUsername(user),
        createdAt: new Date()
      })
    });

    setNewComments(prev => ({ ...prev, [postId]: '' }));
  };

  const handleLike = async (postId) => {
    if (!user) return;

    const postRef = doc(firestore, 'posts', postId);
    const post = posts.find(p => p.id === postId);
    
    if (!post) return;

    const likes = Array.isArray(post.likes) ? post.likes : [];
    
    if (likes.includes(user.uid)) {
      await updateDoc(postRef, {
        likes: arrayRemove(user.uid)
      });
    } else {
      await updateDoc(postRef, {
        likes: arrayUnion(user.uid)
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100">
      <Header />
      
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-teal-700 mb-8 text-center font-rbt">ㅁYㅇ 커뮤니티</h1>
        
        {user ? (
          <form onSubmit={handleSubmit} className="mb-8">
            <textarea
              value={newPost}
              onChange={(e) => setNewPost(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              placeholder="당신의 생각을 공유해주세요..."
              rows="3"
            />
            <button type="submit" className="mt-2 bg-teal-500 text-white px-4 py-2 rounded-lg hover:bg-teal-600 transition-colors flex items-center">
              <Send className="mr-2" size={18} />
              게시하기
            </button>
          </form>
        ) : (
          <p className="text-center mb-8 text-gray-600">글을 작성하려면 로그인이 필요합니다.</p>
        )}

        <div className="space-y-6">
          {posts.map((post) => {
            const likes = Array.isArray(post.likes) ? post.likes : [];
            return (
              <div key={post.id} className="bg-white rounded-lg shadow-md p-4">
                <p className="text-gray-800 mb-2">{post.content}</p>
                <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
                  <span>{post.authorName}</span>
                  <div className="flex items-center space-x-4">
                    <button className="flex items-center space-x-1 hover:text-teal-500 transition-colors">
                      <MessageSquare size={16} />
                      <span>{post.comments.length}</span>
                    </button>
                    <button 
                      onClick={() => handleLike(post.id)} 
                      className={`flex items-center space-x-1 transition-colors ${likes.includes(user?.uid) ? 'text-teal-500' : 'hover:text-teal-500'}`}
                    >
                      <ThumbsUp size={16} />
                      <span>{likes.length}</span>
                    </button>
                  </div>
                </div>
                
                {post.comments.map((comment, index) => (
                  <Comment key={index} comment={comment} />
                ))}

                {user && (
                  <form onSubmit={(e) => { e.preventDefault(); handleCommentSubmit(post.id); }} className="mt-4">
                    <textarea
                      value={newComments[post.id] || ''}
                      onChange={(e) => setNewComments(prev => ({ ...prev, [post.id]: e.target.value }))}
                      className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      placeholder="댓글을 작성해주세요..."
                      rows="2"
                    />
                    <button type="submit" className="mt-2 bg-teal-500 text-white px-3 py-1 rounded-lg hover:bg-teal-600 transition-colors text-sm flex items-center">
                      <Send className="mr-1" size={14} />
                      댓글 작성
                    </button>
                  </form>
                )}
              </div>
            );
          })}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default CommunityPage;