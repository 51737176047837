const samplePoems = [
  { id: 1, title: "-의 기원", content: "모든 것은 어디서 시작되었을까? 그 기원을 찾기 위해 우리는 발걸음을 돌리지만, 그 끝은 언제나 새로운 시작을 의미한다." },
  { id: 2, title: "정원사에 대한 멍든 우화", content: "나무가 자랄 때마다 정원사의 손에 남은 멍들, 그만큼 사랑이 깊었다." },
  { id: 3, title: "헨젤과 크럼블", content: "길을 따라 떨어진 조각들이 우리를 어디로 이끌었는지 기억나지 않는다." },
  { id: 4, title: "날이면 날마다 날만 생각하는 날들", content: "하루하루가 날아가는 것처럼, 우리는 멈추지 않았다." },
  { id: 5, title: "나에게서 도망치는 일은 힘들고", content: "내 그림자가 어디를 가든 나를 따라다니듯, 나는 나를 버릴 수 없었다." },
  { id: 6, title: "줄줄이 낳으면 뭐가 남나요?", content: "생명이 이어질 때마다, 우리가 잃는 것과 얻는 것은 무엇일까." },
  { id: 7, title: "여름 구름을 통과하며", content: "너른 하늘을 지나며 손끝에 닿는 것은 차가운 구름일까, 뜨거운 태양일까." },
  { id: 8, title: "우리 그러지 말자", content: "싸움도, 오해도, 그리고 슬픔도 그만두고 다시 시작하자." },
  { id: 9, title: "안녕하세요 마리아 님", content: "매일 당신에게 인사를 건네지만, 마음 속 말은 전하지 못한다." },
  { id: 10, title: "지옥에서 자살하기", content: "이미 끝이라 생각한 곳에서 다시 시작하는 것은 무엇일까." },
  { id: 11, title: "복수는 니들의 것", content: "너희가 그토록 원한 복수는 결국 너희를 삼킬 것이다." },
  { id: 12, title: "낭만적인 킬러", content: "차가운 손길에도 불구하고, 마음 속에는 언제나 낭만이 피어오른다." },
  { id: 13, title: "쓰이지 않은", content: "여전히 쓰여지지 않은 이야기가 가득하다. 누가 그것을 쓸 것인가." },
  { id: 14, title: "good being", content: "평범한 삶이 얼마나 소중한 것인지, 우리는 잊지 말아야 한다." },
  { id: 15, title: "아가미 고래의 동물원 대탈출!!", content: "물 밖을 나와 자유를 찾아 떠나는 고래의 모험." },
  { id: 16, title: "바나나 모놀로그", content: "한 줄기 바나나처럼 혼자 남아, 세상에 말을 건넨다." },
  { id: 17, title: "어제에서 온 텍스트 메시지", content: "어제 보내진 말들이 오늘 내 마음에 도착했다. 타이밍은 늦었지만 그 말들은 여전히 내 안에서 울려퍼진다." },
  { id: 18, title: "잘 부풀어 버리는 편이야", content: "작은 것들이 커져갈 때, 나는 더욱 더 가볍게 부푼다." },
  { id: 19, title: "심사위원", content: "판단을 내리는 이들 앞에서, 우리는 과연 무엇을 보여줄 수 있을까." },
  { id: 20, title: "말하지 못한 것", content: "그때 말하지 못한 것이 지금도 내 안에서 나를 부른다." },
  { id: 21, title: "당신으로 시작해 나에게로 돌아오는 여행", content: "여행이 끝나면 결국 다시 나로 돌아온다." },
  { id: 22, title: "짝짝이", content: "서로 맞지 않더라도, 결국 우리는 함께한다." },
  { id: 23, title: "52헤르츠 인간들", content: "고독한 주파수를 타고, 우리의 목소리는 닿지 않는다." },
  { id: 24, title: "하늘을 나는 여자", content: "지상과 하늘 사이를 떠도는 그녀, 그 자유를 부러워할 수밖에 없다." },
  { id: 25, title: "이촌동과 기억", content: "이촌동 골목길을 걸으며, 잃어버린 기억들을 주워 담는다." },
  { id: 26, title: "부양", content: "바닥을 떠나기 위해 우리는 무엇을 내려놓아야 하는가." },
  { id: 27, title: "당첨이에요", content: "예상치 못한 복권 당첨, 그러나 그 안에 담긴 의미는 무엇일까." },
  { id: 28, title: "자이언트 낙타를 쓰다듬는 일곱 가지 방법", content: "거대한 존재 앞에서도 부드러운 손길은 잊지 말아야 한다." },
  { id: 29, title: "펑!", content: "폭발과 함께 모든 것이 새로 시작된다." },
  { id: 30, title: "마법이란……!?", content: "그저 일상 속에서 발견한 작은 기적일 뿐이다." },
  { id: 31, title: "바다에 사는 여자", content: "깊은 바다 속, 그녀는 누구보다도 자유로웠다." },
  { id: 32, title: "하나개", content: "고요한 바닷가에서 홀로 있는 순간, 나는 온전히 하나가 된다." },
  { id: 33, title: "사이토자키 해변에서", content: "그 해변에서 본 것은 단순한 풍경이 아니라, 나 자신이었다." }
];


export default samplePoems;
