import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import IntroPage from './pages/IntroPage';
import MainPage from './pages/MainPage';
import SalesPage from './pages/SalesPage';
import NotesPage from './pages/NotesPage';
import MeetAuthorPage from './pages/MeetAuthorPage';
import AboutMyoPage from './pages/AboutMyoPage';
import CommunityPage from './pages/CommunityPage';
import AuthPage from './pages/AuthPage';
import PsychTestPage from './pages/PsychTestPage'

// import HomePage from './pages/HomePage';
// import BookRotatePage from './pages/BookRotatePage'
//import GalleryPage from './pages/GalleryPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IntroPage />} />
        {/* <Route path="/home" element={<HomePage />} /> */}
        <Route path="/main" element={<MainPage />} />
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/notes" element={<NotesPage />} />
        <Route path="/meetauthor" element={<MeetAuthorPage />} />
        <Route path="/aboutmyo" element={<AboutMyoPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/auth" element={<AuthPage />} />
        {/* <Route path="/bookrotate" element={<BookRotatePage />} /> */}
        {/* <Route path="/gallery" element={<GalleryPage />} /> */}
        <Route path="/psychtest" element={<PsychTestPage />} />
      </Routes>
    </Router>
  );
};

export default App;
