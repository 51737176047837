import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDfX6Z8ffKOP-FXu-w_57gi_Z_wom2QDas",
  authDomain: "myo-homepage-test.firebaseapp.com",
  projectId: "myo-homepage-test",
  storageBucket: "myo-homepage-test.appspot.com",
  messagingSenderId: "419372028717",
  appId: "1:419372028717:web:8195fa2ffdc5a7c3a179a4",
  measurementId: "G-LW3851YPHP"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();