import React, { useState, useEffect } from 'react';
import { Save, ChevronDown, ChevronUp } from 'lucide-react';
import Header from '../components/Header';
import samplePoems from '../data/samplePoems';  // 데이터 import
import Footer from '../components/Footer';

const NotesPage = () => {
  const [poems, setPoems] = useState([]);
  const [selectedPoem, setSelectedPoem] = useState(null);
  const [newNote, setNewNote] = useState('');

  useEffect(() => {
    // API 호출 대신 samplePoems 데이터를 설정합니다.
    setPoems(samplePoems);
  }, []);

  const handleSaveNote = () => {
    if (selectedPoem && newNote.trim()) {
      const updatedPoems = poems.map(poem => 
        poem.id === selectedPoem.id 
          ? { ...poem, explanation: newNote } 
          : poem
      );
      setPoems(updatedPoems);
      setNewNote('');
      setSelectedPoem(null);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-200">
      <Header />

      <main className="container mx-auto px-6 py-8">
        <h2 className="text-3xl font-bold text-teal-700 mb-6 font-rbt">창작노트</h2>
        
        <div className="space-y-4">
          {poems.map((poem) => (
            <div key={poem.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div 
                className="p-4 cursor-pointer flex justify-between items-center"
                onClick={() => setSelectedPoem(selectedPoem?.id === poem.id ? null : poem)}
              >
                <h3 className="text-xl font-semibold text-teal-600">{poem.title}</h3>
                {selectedPoem?.id === poem.id ? <ChevronUp /> : <ChevronDown />}
              </div>
              {selectedPoem?.id === poem.id && (
                <div className="p-4 bg-teal-50">
                  <p className="text-gray-700 mb-4">{poem.content}</p>
                  {poem.explanation && (
                    <div className="bg-white p-3 rounded-md mb-4">
                      <h4 className="font-semibold text-teal-700 mb-2">설명:</h4>
                      <p className="text-gray-600">{poem.explanation}</p>
                    </div>
                  )}
                  <textarea
                    className="w-full h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-teal-500"
                    placeholder="이 시에 대한 설명을 작성해주세요..."
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                  ></textarea>
                  <button
                    className="mt-4 bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition-colors flex items-center"
                    onClick={handleSaveNote}
                  >
                    <Save className="mr-2 h-5 w-5" />
                    설명 저장하기
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default NotesPage;
