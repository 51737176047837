import React from 'react';

const Comment = ({ comment }) => {
  return (
    <div className="bg-gray-50 rounded p-2 mb-2">
      <p className="text-sm text-gray-800">{comment.content}</p>
      <div className="flex justify-between items-center mt-1">
        <span className="text-xs text-gray-500">{comment.authorName}</span>
        <span className="text-xs text-gray-400">
          {comment.createdAt.toDate().toLocaleDateString()}
        </span>
      </div>
    </div>
  );
};

export default Comment;