import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Calendar as CalendarIcon, Clock, MapPin } from 'lucide-react';
import Calendar from '../components/Calendar';
import GoogleMap from '../components/GoogleMap';

const MeetAuthorPage = () => {
  // 가상의 만남 일정 데이터
  const events = [
    { date: '2024-09-12', time: '18:00', title: '시 버스킹(우천 취소...)', location: '신촌 아리수 공연장' },
    { date: '2024-09-19', time: '19:00', title: '시 버스킹', location: '신촌 아리수 공연장' },
    { date: '2024-09-28', time: '12:00', title: '플리마켓(예정)', location: '금천구' },
    { date: '2024-10-08', time: '10:00', title: 'ㅁYㅇ 익선 팝업스토어', location: '익선스페이스' },
    { date: '2024-10-09', time: '10:00', title: 'ㅁYㅇ 익선 팝업스토어', location: '익선스페이스' },
    { date: '2024-10-10', time: '10:00', title: 'ㅁYㅇ 익선 팝업스토어', location: '익선스페이스' },
    { date: '2024-10-19', time: '10:00', title: '신촌 청년잡화점(예정)', location: '대현문화공원' },
    { date: '2024-10-26', time: '10:00', title: '신촌 청년잡화점(예정)', location: '대현문화공원' },
  ];

  // 2024년 9월과 10월의 날짜 데이터 생성
  const generateCalendarDays = (year, month) => {
    const firstDay = new Date(year, month - 1, 1).getDay();
    const daysInMonth = new Date(year, month, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month - 1, i));
    }

    return days;
  };

  const september = generateCalendarDays(2024, 9);
  const october = generateCalendarDays(2024, 10);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100">
      <Header />
      
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-teal-700 mb-8 text-center font-rbt">ㅁYㅇ 와의 만남</h1>
        
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <div className="flex items-center mb-6">
            <CalendarIcon className="w-8 h-8 text-teal-500 mr-3" />
            <h2 className="text-2xl font-semibold text-teal-700 font-rbt">다가오는 일정</h2>
          </div>
          <Calendar month={9} year={2024} days={september} events={events} />
          <Calendar month={10} year={2024} days={october} events={events} />
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-teal-700 font-rbt">일정 안내</h2>
          <p className="text-gray-600 leading-relaxed">
            작가와의 만남 일정은 변경될 수 있습니다. 정확한 일정과 장소는 별도 공지를 확인해 주세요.
            참여를 원하시는 분은 냅다 달려와주시길 바랍니다. 저희가 쑥스럽게 맞이할게요!
          </p>
          <div className="mt-6 space-y-4">
            {events.map((event, index) => (
              <div key={index} className="bg-teal-50 rounded-lg p-4 flex items-start">
                <CalendarIcon className="w-5 h-5 text-teal-500 mr-3 mt-1" />
                <div>
                  <h3 className="font-semibold text-teal-700">{event.title}</h3>
                  <p className="text-sm text-gray-600 mt-1">
                    <span className="inline-flex items-center mr-3">
                      <Clock className="w-4 h-4 mr-1" /> {event.date} {event.time}
                    </span>
                    <span className="inline-flex items-center">
                      <MapPin className="w-4 h-4 mr-1" /> {event.location}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-teal-700 font-rbt">이벤트 위치</h2>
          <GoogleMap 
            src="https://www.google.com/maps/d/u/0/embed?mid=1hVQb2GVz3_k6SG241fd-vqYGlz3_CGE&ehbc=2E312F"
            title="ㅁYㅇ 이벤트 위치 지도"
          />
        </div>

      </main>

      <Footer />
    </div>
  );
};

export default MeetAuthorPage;